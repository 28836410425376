const lang = {
  common: {
    add: 'thêm',
    edit: 'chỉnh sửa',
    delete: 'xóa bỏ',
    cancel: 'Hủy bỏ',
    today: 'hôm nay',
    noData: 'Không có dữ liệu',
    noMore: 'không còn nữa',
    loading: 'Đang tải',
    downloading: 'Đang tải xuống',
  },
  placeholder: {
    default: '请输入',
    betting: '投注金额',
  },
  field: {
    betAmount: '投注金额',
    betBonus: '返还金额',
    bet: '投注',
    fromAddress: '转出地址',
    toAddress: '转入地址',
  },
  title: {
    record: '抽奖记录',
    address: '我的地址',
  },
  tabTitle: {
    g01: '幸运数字',
    g02: '幸运大小',
    g03: '幸运单双',
    g04: '幸运龙虎',
  },
  button: {
    betting: '马上投注',
    bettingBill: '投注账单',
    address: '我的地址',
    rebet: '重新投注',
    congratulations: '好运连连',
    goodLuck: '迎接好运',
    onlySuccess: '只看中奖',
    copy: '复制',
  },
  radio: {
    big: '大',
    small: '小',
    single: '单',
    double: '双',
    dragon: '龙',
    tiger: '虎',
  },
  info: {
    tip1: '本玩法接受资金[[min]]-[[max]]USDT',
    tip2: '获得您转账的Block Hash作为判定依据',
    tip3: '我们将及时返奖[[odds]]倍的USDT到您的账户',
    tip4: '仅支持整数金额参与，小数部分自动忽略',
    g01: {
      title: '抽中尾数两位就能赢得[[odds]]倍返奖！',
      tip: 'Block Hash最后两位分别为字母和数字为赢',
    },
    g02: {
      title: '大小由你，成倍快乐！',
      tip: 'Block Hash最后一位数字作为开奖结果\n0，1，2，3，4识别为小；\n5，6，7，8，9识别为大；\n无数字大小均不赢',
    },
    g03: {
      title: '要单要双，都能中奖',
      tip: 'Block Hash最后一位数字作为开奖结果\n1，3，5，7，9识别为单；\n0，2，4，6，8识别为双；\n无数字单双均不赢',
    },
    g04: {
      title: '龙争虎斗，稳赢是你',
      tip: 'Block Hash第一位数字作为龙，\n最后一位数字为虎，龙大于虎龙赢\n虎大于龙虎赢，相等龙虎均不赢\n(Block hash出现1个及以下数字视为相等)',
    },
    bonusTip1: '可赢',
    bonusTip2: '（投注金额*倍率）',
    betFail: '投注失败',
    waitingTitle: '一大波幸运正在赶来',
    waiting: '请等待结果返回..',
    winTitle: '恭喜你',
    win: '赢得',
    loseTitle: '差一点哦！幸运在路上',
  },
  alert: {
    default: '温馨提示',
    warnning: '提示',
  },
  notify: {
    operateSuccess: '处理成功',
    copySuccess: '复制成功',
    sentSuccess: '发送成功',
  },
  status: {
    fail: '失败',
    processing: '处理中',
    win: '已中奖',
    lose: '未中奖',
    notDrawn: '未开奖',
  },
}

export default lang;