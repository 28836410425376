// lang文件夹下的index.js 
// zh, en 分别为两种语言文件
import { createI18n } from 'vue-i18n';
import { Local as vLocal } from "@/assets/lang/vantLocale";
import { Local as mLocal } from "@/assets/lang/momentLocale";
import zh from './zh_CN';
import en from './en_US';
import vi from './vi_VN';
// import ms from './ms_MY';
// import tr from './tr_TR';
// import es from './es_ES';
// import pt from './pt_PT';
// import ru from './ru_RU';
// import th from './th_TH';
// import ar from './ar_EG';

const i18n = createI18n({
  // legacy: false,
  // globalInjection: true,
  locale: localStorage.getItem('i18n') || 'en_US',
  allowComposition: true, // you need to specify that!
  messages: {
    'zh_CN': zh,
    'en_US': en,
    'vi_VN': vi,
    // 'ms_MY': ms,
    // 'tr_TR': tr,
    // 'es_ES': es,
    // 'pt_PT': pt,
    // 'ru_RU': ru,
    // 'th_TH': th,
    // 'ar_EG': ar,
  }
});

export function Local(mylang) {
  i18n.global.locale = mylang;
  vLocal(mylang);
  mLocal(mylang);
}

export default i18n;