import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import moment from 'moment';
import 'lib-flexible';
import SocketService from "@/api/websocket";
import i18n, { Local } from '@/assets/lang/index';
import precision from '@/common/precision';
import {
  Col,
  Row,
  Icon,
  Tag,
  NavBar,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Image,
  Cell,
  CellGroup,
  List,
  Form,
  Field,
  Button,
  Picker,
  Radio,
  RadioGroup,
  Switch,
  Calendar,
  Progress,
  TreeSelect,
  Popup,
  Empty,
  PullRefresh,
  Sticky,
  Toast,
  Notify,
  Dialog,
  ActionSheet,
  CountDown,
  PasswordInput,
  NumberKeyboard,
  Lazyload,
  ConfigProvider
} from 'vant';

const app = createApp(App);
app.use(router);
app.use(Col);
app.use(Row);
app.use(Icon);
app.use(Tag);
app.use(NavBar);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Tab);
app.use(Tabs);
app.use(Image);
app.use(Cell);
app.use(CellGroup);
app.use(List);
app.use(Form);
app.use(Field);
app.use(Button);
app.use(Picker);
app.use(Radio);
app.use(RadioGroup);
app.use(Switch);
app.use(Calendar);
app.use(Progress);
app.use(TreeSelect);
app.use(Popup);
app.use(Empty);
app.use(PullRefresh);
app.use(Sticky);
app.use(Toast);
app.use(Notify);
app.use(Dialog);
app.use(ActionSheet);
app.use(CountDown);
app.use(PasswordInput);
app.use(NumberKeyboard);
app.use(Lazyload);
app.use(ConfigProvider);
app.use(i18n);
app.mount('#app');

var search = window.location.search.split('?')[1];
var urlString = search ? search.split('&') : [];
var urlParam = {};
for (var i = 0; i < urlString.length; i++) {
  var _param = urlString[i].split('=');
  urlParam[_param[0]] = _param[1];
}

const lang = urlParam.lang;
if (lang) {
  Local(lang);
}
if (urlParam.token) {
  localStorage.setItem('token', urlParam.token);
}

// SocketService.Instance.connect(moment().unix() + Math.random().toString(36).substring(2));
// app.config.globalProperties.$socketServe= SocketService.Instance;

app.config.globalProperties.$primary = process.env.VUE_APP_PRIMARY;
app.config.globalProperties.$filters = {
  formatDate(value, formatType) {
    const DEFAULT_DATE_FORMAT = {
      DATE: 'DD MMMM YYYY',
      DATE_SELECT: 'DD-MM-YYYY',
      DATE_MONTH: 'DD MMM',
      DATE_TIME: 'DD-MM hh:mm A',
      DATE_TIME_LONG: 'DD-MM-YYYY hh:mm A',
    };
    const ZH_DATE_FORMAT = {
      DATE: 'YY年M月D日',
      DATE_SELECT: 'YYYY年M月D日',
      DATE_MONTH: 'MMMDD日',
      DATE_TIME: 'MM-DD hh:mm A',
      DATE_TIME_LONG: 'YYYY-MM-DD hh:mm A',
    };
    const DATE_FORMAT = {
      'en_US': DEFAULT_DATE_FORMAT,
      'zh_CN': ZH_DATE_FORMAT,
      'zh_TW': ZH_DATE_FORMAT,
    };
    var lang = localStorage.getItem('i18n') || 'en_US';
    var langFormat = DATE_FORMAT[lang] || DEFAULT_DATE_FORMAT;
    var formatStr = langFormat[formatType];
    return moment(value).format(formatStr || 'YYYY-MM-DD HH:mm');
  },
}

Number.prototype.upFixed = function (fix) {
  // num为原数字，fix是保留的小数位数
  let num = this;
  let result = '0'
  if (Number(num) && fix > 0) { // 简单的做个判断
    fix = +fix || 2
    num = num + ''
    if (/e/.test(num)) { // 如果是包含e字符的数字直接返回
      result = num
    } else if (!/\./.test(num)) { // 如果没有小数点
      result = num + `.${Array(fix + 1).join('0')}`
    } else { // 如果有小数点
      num = num + `${Array(fix + 1).join('0')}`
      let reg = new RegExp(`-?\\d*\\.\\d{0,${fix}}`)
      let floorStr = reg.exec(num)[0]
      if (+floorStr >= +num) {
        result = floorStr
      } else {
        let floorNumber = +floorStr + +`0.${Array(fix).join('0')}1`
        let point = /\./.test(floorNumber + '') ? '' : '.'
        let floorStr2 = floorNumber + point + `${Array(fix + 1).join('0')}`
        result = reg.exec(floorStr2)[0]
      }
    }
  }
  return result
};

Number.prototype.downFixed = function (fix) {
  let strs = this.toString().split(".")
  let length = strs[1] ? strs[1].length : 0;
  if(length >= fix) {
    return strs[0] + '.' + strs[1].slice(0,fix);
  } else {
    return this;
  }
};

String.prototype.downFixed = function (fix) {
  let strs = this.toString().split(".")
  let length = strs[1] ? strs[1].length : 0;
  if(length >= fix) {
    return strs[0] + '.' + strs[1].slice(0,fix);
  } else {
    return this;
  }
};

String.prototype.format = function() {
  if(arguments.length == 0) return this;
  var obj = arguments[0];
  var s = this;
  for(var key in obj) {
    s = s.replace(new RegExp("\\[\\[" + key + "\\]\\]", "g"), obj[key]);
  }
  return s;
}

let u = navigator.userAgent;
let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
if(isAndroid) {//处理安卓输入框聚焦时被遮盖问题
   window.addEventListener('resize', function() {
    if(document.activeElement.tagName=='INPUT' || document.activeElement.tagName=='TEXTAREA') {
      window.setTimeout(function() {
        document.activeElement.scrollIntoView();
      }, 0);
    }
   })
}

