export default class SocketService {
  // 单例
  static instance = null
  static get Instance() {
    if (!this.instance) {
      this.instance = new SocketService()
    }
    return this.instance
  }

  // 和服务端连接的socket对象
  ws = null

  // 存储回调函数
  callBackMapping = {}

  // 标识是否连接成功
  connected = false

  // 记录重试的次数
  sendRetryCount = 0

  // 重新连接尝试的次数
  connectRetryCount = 0
	
	// 心跳检测
	// 30分钟检测一次
	timeout = 30000
	// 心跳倒计时
	timeoutObj = null;
	// 关闭倒计时
	

  //  定义连接服务器的方法
  connect(clientId) {
    // 连接服务器
    if (!window.WebSocket) {
      return console.log('您的浏览器不支持WebSocket')
    }
		let new_url = this.getWsLocation("/webSocket/socketServer/");
    let socketUrl = new_url + clientId;
		console.log(socketUrl);
    this.ws = new WebSocket(socketUrl);
		
		this.ws.onerror= (e) => {
			console.log(e)
		}

    // 连接成功的事件
    this.ws.onopen = () => {
      console.log('连接服务端成功了')
      this.connected = true
      // 重置重新连接的次数
      this.connectRetryCount = 0
			// 开启心跳检测
			this.ws.heartcheck();
    }
    // 1.连接服务端失败
    // 2.当连接成功之后, 服务器关闭的情况
    this.ws.onclose = () => {
      console.log('连接服务端失败')
      this.connected = false
      this.connectRetryCount++
      setTimeout(() => {
        this.connect(clientId)
      }, 500 * this.connectRetryCount)
    }
		// 心跳检测
		this.ws.heartcheck = () => {
			this.timeoutObj && clearInterval(this.timeoutObj)
			this.timeoutObj = setInterval(() => {
				
				if(this.connected === true) {
					console.log("Heartbeat is normal.");
					this.send({code : 'heartCheck'}, true);
				} else {
					console.log("Heartbeat detection is abnormal. Reconnect");
					this.connect(clientId);
				}
			}, this.timeout)
		}
    // 得到服务端发送过来的数据
    this.ws.onmessage = msg => {
      const { code, data} = JSON.parse(msg.data) || {}
      console.log(code, 'The data is obtained from the server')
      if (this.callBackMapping[code]) {
        this.callBackMapping[code](data);
      }
    }
  }

  // 回调函数的注册
  registerCallBack (socketType, callBack) {
    this.callBackMapping[socketType] = callBack
  }

  // 取消某一个回调函数
  unRegisterCallBack (socketType) {
    this.callBackMapping[socketType] = null
  }

  // 发送数据的方法
  send (data,once) {
    console.log("Send data to the server:"+data)
    // 判断此时此刻有没有连接成功
    if (this.connected) {
      this.sendRetryCount = 0
      try {
        this.ws.send(JSON.stringify(data))
      }catch (e) {
        this.ws.send(data)
      }
    } else {
      if(once) {
        return
      }
      this.sendRetryCount++
      setTimeout(() => {
        this.send(data)
      }, this.sendRetryCount * 500)
    }
  }
	
	getWsLocation(uri) {
			let cur_url = window.location
			let ws_url = ''
			if (cur_url.protocol === 'https:') {
				ws_url = 'wss:'
			} else {
				ws_url = 'ws:'
			}
			ws_url += '//' + cur_url.host
			ws_url +=  uri
			return ws_url
		}

}