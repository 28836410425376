// vant 国际化
import { Locale } from 'vant';
import zhCN from 'vant/lib/locale/lang/zh-CN';
import enUS from 'vant/lib/locale/lang/en-US';
import viVN from 'vant/lib/locale/lang/vi-VN';
 
function changeLang(newlang) {
  let vantLang = newlang.replace('_', '-');
  switch (vantLang) {
    case 'zh-CN':
      Locale.use(vantLang, zhCN);
      localStorage.setItem('i18n', newlang)
      break;
    // case 'tr-TR':
    //   Locale.use(vantLang, trTR);
    //   localStorage.setItem('i18n', newlang)
    //   break;
    case 'vi-VN':
      Locale.use(vantLang, viVN);
      localStorage.setItem('i18n', newlang)
      break;
    // case 'es-ES':
    //   Locale.use(vantLang, esES);
    //   localStorage.setItem('i18n', newlang)
    //   break;
    // case 'ru-RU':
    //   Locale.use(vantLang, ruRU);
    //   localStorage.setItem('i18n', newlang)
    //   break;
    // case 'th-TH':
    //   Locale.use(vantLang, thTH);
    //   localStorage.setItem('i18n', newlang)
    //   break;
    // case 'ms-MY':
    //   Locale.use('en-US', enUS); //vant没有对应的马来西亚语 使用英语
    //   localStorage.setItem('i18n', newlang)
    //   break;
    // case 'pt-PT':
    //   Locale.use('en-US', enUS); //vant没有对应的葡萄牙语 使用英语
    //   localStorage.setItem('i18n', newlang)
    //   break;
    // case 'ar-EG':
    //   Locale.use('en-US', enUS); //vant没有对应的阿拉伯语 使用英语
    //   localStorage.setItem('i18n', newlang)
    //   break;
    default:
      Locale.use('en-US', enUS);
      localStorage.setItem('i18n', 'en_US')
      break;
  }
}
 
export function Local(mylang) {
  changeLang(mylang)
}