// vant 国际化
import moment from 'moment';

function changeLang(newlang) {
  switch (newlang) {
    case 'zh_CN':
      moment.locale('zh-cn');
      break;
    case 'vi_VN':
      moment.locale('vi');
      break;
    // case 'tr_TR':
    //   moment.locale('tr');
    //   break;
    // case 'es_ES':
    //   moment.locale('es');
    //   break;
    // case 'pt_PT':
    //   moment.locale('pt');
    //   break;
    // case 'ru_RU':
    //   moment.locale('ru');
    //   break;
    // case 'th_TH':
    //   moment.locale('th');
    //   break;
    // case 'ar_EG':
    //   moment.locale('ar');
    //   break;
    // case 'ms_MY':
    //   moment.locale('en');
    //   break;
    default:
      moment.locale('en');
      break;
  }
}
 
export function Local(mylang) {
  changeLang(mylang)
}