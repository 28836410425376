<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style lang="less">
@import '/font/iconfont.css';

body {
  font-size: 16px;
  background-color: #f8f7fc;
  -webkit-font-smoothing: antialiased;
}
p { margin: 0; }
.van-button--disabled {
  color: #333 !important; 
  background: #c1c1c1 !important; 
  border-color: #c1c1c1;
}
:root {
  --van-primary-color: @primary-color;
  --light-primary-color: #f9f4ed;
  --van-password-input-height: 45px;
  --van-nav-bar-icon-color: #333;
  --van-field-placeholder-text-color: #C1C1D6;
}

.van-tabs__line {
  background: var(--van-primary-color);
}
.van-tab {
  &.van-tab--active {
    color: var(--van-primary-color);
  }
  .van-tab__text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.order-list {
  padding: 16px;
  .order-block {
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    line-height: 30px;
    padding: 6px 12px;
    margin-bottom: 12px;
    font-size: 15px;
    background: #fff;
    border-radius: 6px;
    .order-sn {
      width: 60%;
    }
    .order-amount {
      width: 40%;
      text-align: right;
    }
    .order-time {
      width: 60%;
      font-size: 13px;
      color: #C1C1D6;
    }
    .order-status {
      width: 40%;
      text-align: right;
      color: blue;
    }
  }
  
  .journal-block {
    margin-bottom: 10px;
    box-shadow: 0px 4px 16px 0px #EBEBEB;
    .van-cell-group {
      background: transparent;
    }
    .journal-title {
      font-size: 12px;
      color: #6F6F93;
      padding: 6px 14px;
      border-radius: 6px 6px 0 0;
      .van-icon {
        margin-right: 6px;
      }
      .journal-sn {
        color: #6F6F93;
        font-size: 11px;
      }
    }
    .journal-detail {
      padding: 10px 14px;
      border-radius: 0 0 6px 6px;
      background: #fff;
    }
    .journal-detail-row {
      padding: 0;
      font-size: 12px;
      background: transparent;
      .van-cell__title {
        flex: 2;
        color: #28293D;
        font-weight: 600;
       }
      .van-cell__value { 
        flex: 2; color: #333;
        &.flex3 { flex: 3 }
        &.flex4 { flex: 4 }
        &.flex5 { flex: 5 }
        
      }
      
    }
    .order-date {
      padding: 6px 14px;
      font-size: 12px;
      color: #6F6F93;
      background: #f9f9f9;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      .order-status {
        color: #6F6F93;
        &::before {
          display: inline-block;
          content: '';
          width: 4px;
          height: 4px;
          margin-right: 4px;
          background: #FF8F00;
          border-radius: 50%;
          vertical-align: middle;
        }
        &.green::before {
          background: #1BA27A;
        }
        &.red::before {
          background: #FF4060;
        }
        &.warn::before {
          background: #FF8F00;
        }
      }
    }
    .btn-wrap {
      display: flex;
      justify-content: center;
      padding: 10px;
      background: #f9f9f9;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      .van-button {
        margin: 0 16px;
        min-width: 80px;
      }
    }
  }
}
</style>
