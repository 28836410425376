import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/index'),
    meta: {
      title: 'Helpslotwin',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  // if (!localStorage.getItem('userInfo') && to.name !== 'login' && to.name !== 'register') {
  //   next({ name: 'login' });
  // }
  next();
});

export { router };
